<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          {{ title }}
        </b-card-title>
        <b-card-sub-title>{{ data.start_date+' - '+data.end_date }}Tarihleri Arası Temsilci Satış Grafiği</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1" />

      </div>
      <!--/ badge -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="chartData"
        type="line"
        height="400"
        :options="chartData.chartOptions"
        :series="chartData.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      colors: [],
      chartData: null,
    }
  },
  computed: {

  },
  watch: {
    data() {
      this.makeChartData()
    },
  },
  created() {
    this.makeChartData()
  },
  methods: {
    makeChartData() {
      const seriesData = [
      ]
      const CategoriesData = []
      const Ccolors = []

      // eslint-disable-next-line no-unused-vars
      const currencyName = this.data.currency_name
      this.data.sales_data.forEach(val => {
        CategoriesData.push(val.doc_date)
        val.sales_data.forEach(salesVal => {
          let isExist = false
          seriesData.forEach(seriesVal => {
            if (seriesVal.name === `${salesVal.user_name}(${salesVal.uyum_code})`) {
              seriesVal.data.push(salesVal.sales)

              isExist = true
            }
          })
          if (!isExist) {
            Ccolors.push(this.getRandomColor)
            seriesData.push({ name: `${salesVal.user_name}(${salesVal.uyum_code})`, data: [salesVal.sales] })
          }
        })
      })
      if (this.colors.length <= 0) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.colors = Ccolors
      }
      const cData = {

        series: seriesData,
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            horizontalAlign: 'center',
            fontFamily: 'Montserrat',
          },
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: true,
            },
          },
          markers: {
            strokeWidth: 10,
            strokeOpacity: 1,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {

          },
          xaxis: {
            categories: CategoriesData,
          },
          yaxis: {
          // opposite: isRtl,
          },
        },
      }
      this.chartData = cData
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF'
      let color = '#'
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
  },
}
</script>
