<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Temsilci Satış Sıralaması
              </b-card-title>

            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>

          <b-card-body>
            <b-table
              small
              :fields="debtTable.fields"
              :items="data"
              responsive="sm"
            >

              <template #cell(order_total)="data">
                <strong>  {{ $staticParams.formatCurrency(data.item.order_total) }}</strong>
              </template>
              <!-- A custom formatted column -->
              <template #cell(time)="data">
                {{ data.value+' DK.' }}
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BTable, BRow, BCol, VBModal,
} from 'bootstrap-vue'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      debtTable: {
        fields: [
          { key: 'order_num', label: 'Sıra' },
          { key: 'user_name', label: 'Temsilci' },
          // A regular column
          { key: 'order_total', label: 'Toplam Satış' },
        ],
      },
      trainingUserList: null,
      seletedData: null,
    }
  },
  methods: {

  },
}
</script>
