<template>
  <div>
    <b-row>
      <b-col
        md="12"
        sm="12"
      >
        <b-card>

          <b-form

            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col md="4"> <b-form-group
                :label="$t('Başlangıç Tarihi')"
                label-for="name"
              >
                <b-form-datepicker
                  id="start_date"
                  v-model="filterData.start_date"
                  class="mb-1"
                />
              </b-form-group></b-col>
              <b-col md="4"> <b-form-group
                :label="$t('Bitiş Tarihi')"
                label-for="name"
              >
                <b-form-datepicker
                  id="end_date"
                  v-model="filterData.end_date"
                  class="mb-1"
                />
              </b-form-group></b-col>
              <b-col md="4">  <!-- Satış Temsilcisi -->
                <b-form-group

                  v-if="usersData && $can('read','report_sales')"
                  :label="$t('Satış Temsilcisi')"
                  label-for="user_id"
                >
                  <v-select
                    id="user_id"
                    v-model="filterData.user_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usersData"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user_id"
                  />
                </b-form-group></b-col>
            </b-row>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="fetchData"
              >
                Filtrele
              </b-button>
            </div>
          </b-form>

        </b-card>

      </b-col>
      <b-col
        v-if="reportData"
        md="12"
      >
        <b-row>
          <b-col md="3">
            <statistic-card-vertical
              color="secondary"
              icon="FlagIcon"
              :statistic="$staticParams.formatCurrency(orderTotal)+' '+reportData.total_sales.main_currency_name"
              statistic-title="Toplam Satış"
            />
          </b-col>
          <b-col md="3">
            <statistic-card-vertical
              color="secondary"
              icon="FlagIcon"
              :statistic="$staticParams.formatCurrency(productCount,0)"
              statistic-title="Satılan Ürün Adedi"
            />
          </b-col>
          <b-col md="3">
            <statistic-card-vertical
              color="secondary"
              icon="FlagIcon"
              :statistic="bestSellerUser"
              statistic-title="En Çok Satış Yapan Temsilci"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="reportData.user_sales_data_range_graph"
            md="12"
          >
            <user-sales-chart-area
              :data="reportData.user_sales_data_range_graph"
              title="Temsilci Satış Grafiği"
            />
          </b-col>
          <b-col
            v-if="reportData.user_sales"
            md="6"
          >
            <user-sales-chart-donut
              :data="reportData.user_sales"
              title="Temsilci Satış Grafiği ( Yüzde Dağılımlı )"
            />
          </b-col>
          <b-col

            md="6"
          >
            <top-buyer-cities
              :startdate="filterData.start_date"
              :enddate="filterData.end_date"
              :userid="filterData.user_id"
              title="Satışa Göre Bölge Sıralaması"
            />
          </b-col>

          <b-col

            md="12"
          >
            <top-selling-products
              :startdate="filterData.start_date"
              :enddate="filterData.end_date"
              :userid="filterData.user_id"
              title="Satışa Göre Ürün Sıralaması"
            />
          </b-col>
          <b-col

            md="6"
          >
            <top-buyer-customers
              :startdate="filterData.start_date"
              :enddate="filterData.end_date"
              :userid="filterData.user_id"
              title="Satışa Göre Müşteri Firma Sıralaması"
            />
          </b-col>
          <b-col

            md="6"
          >
            <top-seller-users
              v-if="reportData"
              :data="reportData.user_sales.sales_data"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div />
  </div></template>

<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BButton, BForm, BFormGroup,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import userSalesChartDonut from './UserSalesChartDonut.vue'
import userSalesChartArea from './UserSalesChartArea.vue'
import topSellingProducts from './TopSellingProducts.vue'
import topBuyerCustomers from './TopBuyerCustomers.vue'
import topBuyerCities from './TopBuyerCities.vue'
import topSellerUsers from './TopSellerUsers.vue'
import reportsStoreModule from '../reportsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    topSellerUsers,
    BCol,
    BFormDatepicker,
    topBuyerCustomers,
    BButton,
    BForm,
    BFormGroup,
    vSelect,
    StatisticCardVertical,
    userSalesChartDonut,
    topBuyerCities,
    userSalesChartArea,
    topSellingProducts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filterData: {
        start_date: null,
        end_date: null,
        user_id: 0,
      },
      reportData: null,

    }
  },
  computed: {
    orderTotal() {
      return `${this.reportData.total_sales.order_total}`
    },
    productCount() {
      return this.reportData.total_sales.order_count
    },
    bestSellerUser() {
      if (this.reportData.user_sales.sales_data.length > 0) {
        return this.reportData.user_sales.sales_data[0].user_name
      }
      return 'Mevcut Değil'
    },

  },
  created() {

  },

  methods: {
    fetchData() {
      store
        .dispatch('app-reports/fetchSalesReport', this.filterData)
        .then(response => {
          this.reportData = response.data.data
        })
        .catch(() => {
        })
    },

  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // eslint-disable-next-line no-unused-vars
    const exData = ref(null)
    const usersData = ref(null)
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })
        arr.unshift({ label: 'Tümü', value: 0 })
        usersData.value = arr
      })
      .catch(() => {
      })

    return {

      // Filter
      avatarText,
      usersData,

      // UI
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
